#languageFabButton {
  background-color: #5a82ff; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  position: fixed;
  top: 70px;
  right: 20px;
  z-index: 99;
  text-align: center; /* Centered text */
  text-decoration: none; /* Remove underline */
  font-size: 25px; /* Increase font size */
  margin: 4px 2px; /* Some margin */
  cursor: pointer; /* Pointer/hand icon */
  border-radius: 12px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth transition */
}

#languageFabButton:hover {
  background-color: #1b58f5; /* Darker green on hover */
}

.language-icon {
  width: 50px;
  height: 50px;
}